import React, { useMemo } from 'react'
import { Navigate } from 'react-router-dom';
import { isValidStoredKey } from '../utils/utilities';

const ProtectedRoutes = ({ children }) => {

    const isAuthenticated = useMemo(() => {
        return isValidStoredKey(['isAuthenticated', 'facility_id', 'login_type'])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children])
    return isAuthenticated ? children : <Navigate to={`/login`} replace={true} />
}

export default ProtectedRoutes