import React, { Fragment } from 'react'

const StickySubHeader = ({ children}) => {
  return (
    <Fragment>
        <div className="sticky_header">
            {children}
        </div>
    </Fragment>
  )
}

export default StickySubHeader