import React, { Fragment } from "react";
import AppHeader from "./header";
import { Layout } from "antd";
import AppFooter from "./footer";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const { Content } = Layout;

const MainLayout = () => {
  const { pathname } = useLocation();

  if (pathname === "/") {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <Fragment>
      <Layout>
        <AppHeader />

        <Content className="site-layout">
          <div className="site-layout-background">
            <Outlet />
          </div>
        </Content>
        <AppFooter />
      </Layout>
    </Fragment>
  );
};

export default MainLayout;
