import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const commentsByCommunicationIdSlice = createSlice({
    name: 'commentsByCommunicationId',
    initialState,
    reducers: {
        commentsByCommunicationIdSuccess: (state, action) => {
            // onSuccess(state, action);
            state.data = action.payload;
            state.isLoading = false;
        },
        commentsByCommunicationIdFailure: (state, action) => {
            // OnFailure(state, action);
            state.data = [];
            state.isLoading = false;
            state.true = false;
        },
        resetCommentsByCommunicationId: () => initialState
    }
})

export const { commentsByCommunicationIdSuccess, commentsByCommunicationIdFailure, resetCommentsByCommunicationId } = commentsByCommunicationIdSlice.actions;

export default commentsByCommunicationIdSlice.reducer