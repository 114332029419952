import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        loginFailure: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = true;
        }
    }

})

export const { loginSuccess, loginFailure } = loginSlice.actions;

export default loginSlice.reducer