import { Fragment } from 'react'
import dashboardSVG from './images/dashboard.svg'
import addCommunicationSVG from './images/notebook.svg'

export const DashboardIcon = (props) => {
    return (
        <Fragment>
            <img src={dashboardSVG} alt="dashboard" {...props} />
        </Fragment>
    )
}

export const CommunicationIcon = (props) => {
    return (
        <Fragment>
            <img src={addCommunicationSVG} alt="addCommunication" {...props} />
        </Fragment>
    )
}