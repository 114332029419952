import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [], error: false, isLoading: true };

export const getCommByIdSlice = createSlice({
    name: 'getCommByFId',
    initialState,
    reducers: {
        getCommByIdSuccess: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        getCommByIdFailure: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
            state.error = true;
        }
    }

})

export const {getCommByIdSuccess, getCommByIdFailure } = getCommByIdSlice.actions;

export default getCommByIdSlice.reducer