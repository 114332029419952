import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import localStore from 'store2';
import JSURL from 'jsurl';
import { isNull, isString, isUndefined } from 'lodash';

export const isValidArray = (arr) => {
    return arr && Array.isArray(arr) && arr.length > 0;
}

export const isValidObject = (obj, keys) => {
    if (isValidArray(keys)) {
        const newObjKeys = isValidObject(obj, []) ? Object.keys(obj) : [];

        if (!isValidArray(newObjKeys)) {
            return false;
        }

        let isValid = keys.length;
        keys.forEach(key => {
            if (newObjKeys.includes(key)) {
                isValid -= 1;
            }
        });
        return isValid === 0;
    }
    return obj && Object.keys(obj).length > 0;
}

export const getAllRequiredHeaders = () => {
    const token = localStore.get('token_id') || "";

    return {
        "token-id": token,
        'majestic-app': isAuthenticated() ? "" : 'facility_management'
    }
}

export const setLocalStorageItems = ({ set = false, setAll = false, item = {}, items = {}, skipKey = []}) => {
    if (set) {
        localStorage.setItem(item.key, item.value)
    } else if (setAll) {
        for ( const key in items){
            if( skipKey.includes(key) ) continue;
            localStorage.setItem(key, items[key])
        }
    }
}

export const getLocalStorageItems = ({get = false, getAll = false, key = null, keys = {}}) => {
    if (get) {
        const value = localStore.get(key);
        return { [key]: value };
    } else if (getAll) {
        return localStore.getAll(keys);
    }
    return null;
}

export const SendNotification = ({ type, message, duration }) => {
    let options = {
        autoClose: 3000 || duration
    }
    if (type === 'success') {
        toast.success(message, options)
    } else if (type === 'error') {
        toast.error(message, options)
    } else if (type === 'warning') {
        toast.warn(message, options)
    }
}

export const useQueryParam = (key) => {
    let [searchParams, setSearchParams] = useSearchParams();
    let paramValue = searchParams.get(key);

    let value = useMemo(() => JSURL.parse(JSURL.stringify(paramValue)), [paramValue]);

    let setValue = useCallback(
        (newValue) => {
            let newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(key, JSURL.stringify(newValue));
            setSearchParams(newSearchParams);
        },
        [key, searchParams, setSearchParams]
    );

    return [value, setValue];
}

export const isValidStoredKey = (keys = []) => {
    let isValidKey = true;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (localStore.has(key)) {
            const value = localStore.get(key);
            if (isUndefined(value) && isNull(value)) {
                isValidKey = false;
                break;
            } else if (isString(value) && value.length === 0) {
                isValidKey = false;
                break;
            }
        } else {
            isValidKey = false;
            break;
        }
    }

    return isValidKey;
}

export const useAppData = () => {
    
    const { facility_name, login_type, id, facility_id, user_name } = useMemo(() => getLocalStorageItems({ getAll: true, keys: ['facility_name', 'login_type', 'id', 'facility_id', 'user_name']}), [])

    return { facility_name, login_type, id, facility_id, user_name }
}

export const getValidDataFromResponse = (response) => {
    try {
        if(isValidObject(response)){
            const { data, message, success } = response?.payload;

            return { data, message, success };
        }
    } catch (error) {
        return { data : [] , success : false };
    }
}

export const isAuthenticated = () => { 
    return isValidStoredKey(['isAuthenticated', 'facility_id', 'login_type'])
}