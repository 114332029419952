import { createSlice } from "@reduxjs/toolkit";
import { OnFailure, onSuccess } from "redux/common";

const initialState = { data: [], error: false, isLoading: true };

export const RaiseCommunicationSlices = createSlice({
    name: 'RaiseCommunication',
    initialState,
    reducers: {
        RaiseCommunicationSuccess: (state, action) => {
            onSuccess(state, action);
        },
        RaiseCommunicationFailure: (state, action) => {
            OnFailure(state, action);
        },
        resetRaiseCommunication: () => initialState
    }
})

export const { RaiseCommunicationSuccess, RaiseCommunicationFailure, resetRaiseCommunication } = RaiseCommunicationSlices.actions;

export default RaiseCommunicationSlices.reducer;