import { Fragment } from "react";
import AppRoutes from "./routes";
import './assets/index';
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from './redux/store/store'

function App() {
  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </Fragment>
  );
}

export default App;
