import { configureStore } from '@reduxjs/toolkit'
import loginSlice from '../reducers/slices/loginSlice';
import getCommByFIdSlice from "../reducers/slices/getCommByFIdSlice"
import getCommByIdSlice from "../reducers/slices/getCommByIdSlice"
import themeSlice from '../reducers/slices/theme';
import RaiseCommunicationSlices from '../reducers/slices/raiseCommunication/raiseCommunicationSlice';
import commentsByCommunicationIdSlice from '../reducers/slices/getCommentsByCommunicationIdSlice';

const store = configureStore({
    reducer: {
      loginReducer : loginSlice,
      getCommByFIdSlice:getCommByFIdSlice,
      getCommByIdSlice:getCommByIdSlice,
      themeReducer : themeSlice,
      RaiseCommunicationReducer: RaiseCommunicationSlices,
      commentsByCommunicationIdReducer: commentsByCommunicationIdSlice
    }
  })
  
  export default store;