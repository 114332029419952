import { Avatar, Dropdown, Layout, Menu } from 'antd';
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../common/Logo';
import moment from "moment";
import avtarImage from '../../../assets/images/avtar.jpg'
import { useAppData } from '../../../utils/utilities';
import RestrictedLayout from '../RestrictedLayout';


const { Header } =  Layout;

const menuItem = [

  {
    title: "Sign Out",
    // icon: LogoutOutlined,
    path: "/logout",
  },
];

const AppHeader = () => {

  const { user_name } = useAppData()

  const getCurrentTime = () => {
    return moment().format("dddd, DD MMMM YYYY");
  };

  const profileMenu = (
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Fragment key={i}>
                <Menu.Item>
                  <Link to={el.path}>
                    <span className="font-weight-normal">{el.title}</span>
                  </Link>
                </Menu.Item>
              </Fragment>
            );
          })}
        </Menu>
    </div>
  );


  return (
    <Fragment>
      <Header>
          <div className="container">
            <div className="d-flex justify-content-between text-white">
              <div className="nav_images">
                <Logo />
              </div>
              <RestrictedLayout>
                <div className="aa">
                  <Dropdown
                    placement="bottomRight"
                    overlay={profileMenu}
                    trigger={["click"]}
                  >
                    <Menu className="d-flex align-item-center nav_wrapper" mode="horizontal">
                      <Menu.Item>
                        <Avatar src={avtarImage} />
                      </Menu.Item>
                      <Menu.Item className="pl-0 profile_none2">
                        <div className="mt-3 lh_1-3">
                          <small className="mb-0 text-white fs_14">
                            {getCurrentTime()}
                          </small>
                          <h4 className="mb-0">
                            <span
                              className="font-weight-normal text-white"
                            >
                              Welcome,
                            </span>{" "}
                            <b className='text-white'>
                              {user_name}
                            </b>
                          </h4>
                        </div>
                      </Menu.Item>
                    </Menu>
                  </Dropdown>
                </div>
              </RestrictedLayout>
            </div>
          </div>
        </Header>
    </Fragment>
  )
}

export default AppHeader;