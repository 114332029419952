import axios from "axios";
import errorInterceptor from "./errorInterceptor";


const apiEndpoint = process.env.REACT_APP_API_URL;

const client = axios.create({
    baseURL: apiEndpoint,
    headers: {
        'content-type': 'application/json',
        accept: 'application/json'
    }
})

errorInterceptor(client)

export default client;