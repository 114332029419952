import React, { Fragment } from 'react'
import AsespsisIcon from '../../assets/images/asespsis_icon.png'

const AsespsisLogo = () => {
    const styles = { 
        margin: '0px 0px 0px 7px'
    }
  return (
    <Fragment>
        <img src={AsespsisIcon} alt={'Asespsis'} width='15px' style={styles} />
    </Fragment>
  )
}

export default AsespsisLogo;
