export const onSuccess = (state, action) => {
    return { 
        ...state,
        ...action.payload,
        error:false,
        isLoading:false,
    }
}

export const OnFailure = (state, action) => {
    return { 
        ...state,
        data: [],
        error:true,
        isLoading:false,
    }
}