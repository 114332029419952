import { AppstoreOutlined } from "@ant-design/icons";
import { Button, Layout, Row } from "antd";
import Dashboard from "pages/dashboard";
import React, { Fragment } from "react";
import { FooterConfig } from "../../../configs/appConfig";
import AsespsisLogo from "../../common/asepcisLogo";
import RestrictedLayout from "../RestrictedLayout";

const { Footer } = Layout;

const AppFooter = () => {

  const openPopup = () => {
    document.getElementById("myNav").style.width = "100%";
    document.getElementById("myNav").style.transform = "scale(1)";
  };

  const closeNav = () => {
    // document.getElementById("myNav").style.width = "0%";
    document.getElementById("myNav").style.transform = "scale(0)";
  };

    return (
        <Fragment>
            <Footer>
                <div className="text-center">
                    {FooterConfig}
                    <AsespsisLogo />
                </div>
                <RestrictedLayout>
                    <div>
                        <Button
                            className="global_navigation"
                            onClick={openPopup}
                            icon={<AppstoreOutlined style={{ fontSize: "22px" }} />}
                        ></Button>
                    </div>
                </RestrictedLayout>
            </Footer>
                <div
                    id="myNav"
                    className="overlay"
                    style={{
                        width: "100%",
                        transform: "scale(0)",
                        transformOrigin: "96% 93%",
                    }}
                >
                    <a
                        href="javascript:;"
                        className="closebtn"
                        onClick={closeNav}
                        style={{ marginTop: "80px" }}
                    >
                        &times;
                    </a>
                    <div className="overlay-content">
                        <div className="modal_container">
                            <Row
                                gutter={16}
                                className="justify-content-center justify-content-md-start ml-0 mr-0"
                            >
                              <Dashboard isGlobalNavigation={true} closeNav={closeNav} />
                            </Row>
                        </div>
                    </div>
                </div>
        </Fragment>
    );
};

export default AppFooter;
