import { Modal, Row, Spin } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCommentsByCommunicationId } from 'redux/actions/communicationComments/getCommentsByCommunicationId.action'
import { resetCommentsByCommunicationId } from 'redux/reducers/slices/getCommentsByCommunicationIdSlice'
import { isValidObject } from 'utils/utilities'

const CommentsModal = ({open, onCancel, id}) => {

    const dispatch = useDispatch()

    const commentsByCommunicationIdReducer = useSelector(state => state.commentsByCommunicationIdReducer);

    const [commentsData, setCommentsData] = useState([])

    useEffect(() => {
        dispatch(getCommentsByCommunicationId(id))
        return () => dispatch(resetCommentsByCommunicationId())
    }, [dispatch])

    useEffect(() => {

        const { data } = commentsByCommunicationIdReducer;

        if(isValidObject(data)){
            setCommentsData(data?.data)
        }

    }, [commentsByCommunicationIdReducer])

  return (
    <Fragment>
        <Modal bodyStyle={{overflowY: 'scroll'}} title="Communication Comments" open={open} onCancel={onCancel} footer={null} centered={true} width={1040} >
            {commentsByCommunicationIdReducer.isLoading ? (
                <Row justify='center'>
                    <Spin />
                </Row>
            ) : (
                <>
                {commentsData.map((item, i) => {
    
                    if(!commentsByCommunicationIdReducer.isLoading){
                        return (
                            <Fragment>
                                <div className='modal_body' >
                                    <h3>{item.name}</h3>
                                    <h5>{item.comment}</h5>
                                </div>
                            </Fragment>
                        )
                    } 
                })}
                </>
            )}
      </Modal>
    </Fragment>
  )
}

export default CommentsModal