import { commentsByCommunicationIdFailure, commentsByCommunicationIdSuccess } from "redux/reducers/slices/getCommentsByCommunicationIdSlice"
import { getRequest } from "utils/apiUtils"

export const getCommentsByCommunicationId = (id) => {
    return async (dispatch) => {
        const { error, result } = await getRequest(`query/comment/${id}`)

        if (!error) {
            return dispatch(commentsByCommunicationIdSuccess(result))
        }
        return dispatch(commentsByCommunicationIdFailure(result))

    }
}