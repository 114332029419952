import { getRequest } from "../../../utils/apiUtils"
import {  getCommByFIdSuccess ,getCommByFIdFailure} from "../../reducers/slices/getCommByFIdSlice";

export const getCommByFId = (id) => {
    return async (dispatch) => {
        const { result, error } = await getRequest(`query_by_facility/${id}`, );

        if(!error) {
            return dispatch(getCommByFIdSuccess(result))
        }
        return dispatch(getCommByFIdFailure(result))
    }
}