import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navType: "TOP",
  sideNavTheme: "SIDE_NAV_LIGHT",
  navCollapsed: false,
  topNavColor: "#144881",
  headerNavColor: "#00477E",
  locale: "en",
  currentTheme: "light",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
	toggleCollapsedNav: (state, action) => {
		state.navCollapsed = action.payload;
	},
	sideNavStyleChange: (state, action) => {
		state.sideNavTheme = action.payload;
	},
	changeLocal: (state, action) => {
		state.locale = action.payload
	},
	changeNavType: (state, action) => {
		state.navType = action.payload;
	},
	topNavColorChange: (state, action) => {
		state.topNavColor = action.payload
	},
	headerNavColorChange: (state, action) => {
		state.headerNavColor = action.payload
	},
	toggleMobileNav: (state, action) => {
		state.mobileNav = action.payload
	},
	switchTheme: (state, action) => {
		state.currentTheme = action.payload
	}
  }

});

export const { toggleCollapsedNav, sideNavStyleChange, changeLocal, changeNavType, topNavColorChange, headerNavColorChange, toggleMobileNav, switchTheme} = themeSlice.actions

export default themeSlice.reducer