import React, { Fragment } from 'react'
import majesticLogo from '../../assets/images/logo1.png'

const Logo = () => {
  return (
    <Fragment>
        <img width='150px' src={majesticLogo} alt="Majestic logo"  />
    </Fragment>
  )
}

export default Logo