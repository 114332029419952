import { RaiseCommunicationFailure, RaiseCommunicationSuccess } from "redux/reducers/slices/raiseCommunication/raiseCommunicationSlice";
import { postRequest } from "utils/apiUtils"

export const RaiseCommunicationAction = (data) => {
    return async (dispatch) => {
        const { result, error } = await postRequest(`create/query`, data);

        if (!error) {
            return dispatch(RaiseCommunicationSuccess(result))
        }
        return dispatch(RaiseCommunicationFailure(result))
    }
}