import { Row, Spin } from 'antd'
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const SignOut = () => {

    const navigate = useNavigate()

    useEffect(() => {
        localStorage.clear()
        navigate('/login')
    }, [])

  return (
    <Fragment>
      <div className="login_container">
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Spin />
          </Row>
        </div>
      </div>
    </Fragment>
  )
}

export default SignOut