import React, { Fragment, useEffect, useState } from "react";
import {
    Card,
    Col,
    DatePicker,
    Row,
    Select,
    Table,
    Tag,
    Button,
} from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { useAppData, isValidArray } from "../../utils/utilities";
import { getCommByFId } from "../../redux/actions/login/getCommByFId.action";
import { useDispatch, useSelector } from "react-redux";
import { StickySubHeader } from "components";
import moment from "moment";
import CommentsModal from "./commentsModal";
import { DATE_FORMAT_DD_MM_YYYY } from '../../constants/DateConstant'


const Communication = () => {
    const dispatch = useDispatch();
    const { facility_name } = useAppData();
    const [loading, setLoading] = useState(false);
    const [communicationList, setCommunicationList] = useState([]);
    const [rangePickerValue, setRangePickerValue] = useState({
      startDate: moment().subtract(15, 'd'),
      endDate: moment()
    })
    const [communicationId, setCommunicationId] = useState('')
    const [communicationStatus, setCommunicationStatus] = useState("All")
    const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
    const allCommunicationList = useSelector(
        (state) => state.getCommByFIdSlice.data.data
    );

    // useEffect to fetch data from back-end
    useEffect(() => {
        setLoading(true);
        const id = localStorage.getItem("facility_id");
        dispatch(getCommByFId(id))
            .finally(() => setLoading(false));
    }, [dispatch]);

    // useEffect to filter data by date and communication status
    useEffect(() => {
        if (isValidArray(allCommunicationList)) {
            setCommunicationList(() => {
                return allCommunicationList.filter((item) => {
                    if(communicationStatus === "All"){
                        return (
                           moment(item.date).isBetween(
                                rangePickerValue.startDate.format("YYYY-MM-DD"),
                                rangePickerValue.endDate.format("YYYY-MM-DD"),
                                undefined,
                                "[]"
                            )
                        );
                    }
                    return (
                        moment(item.date).isSame(rangePickerValue.startDate) || moment(item.date).isBetween(
                            rangePickerValue.startDate.format("YYYY-MM-DD"),
                            rangePickerValue.endDate.format("YYYY-MM-DD"),
                            undefined,
                            "[]"
                        ) && (item.status === communicationStatus )
                    );
                });
            });
        }
    }, [allCommunicationList, rangePickerValue, communicationStatus]);


    const handleDateChange = (date, key) => {

        setRangePickerValue((prevState) => {
            if(key === 0){
                if( moment(date).isAfter(prevState.endDate) ){
                    return {
                        startDate: date,
                        endDate: moment(date).add(15, 'd')
                    }
                }
                return {
                    ...prevState,
                    startDate: date
                }
            }
            if(key === 1){
                return {
                    ...prevState,
                    endDate: date
                }
            }
        })
    }

    const handleStatusChange = (status) => {
        setCommunicationStatus(status)
    }

    const openMessageChat = (record,callback) => {
        setCommunicationId(record?.id)
        callback()
    };

    const disabledDate = (current) => {
        return current && current < moment( moment(rangePickerValue.startDate).subtract(1, 'd')).endOf('day');
    }

    const tableColumns = [
        {
            title: "Date",
            key: "1",
            dataIndex: "date",
            render: (_, record) => {
                return (
                    <>
                        { moment(record.date).format(DATE_FORMAT_DD_MM_YYYY) }
                    </>
                )
            }
        },
        {
            title: "Details",
            key: "1",
            dataIndex: "query_detail",
        },
        {
            title: "Room/Area",
            key: "1",
            dataIndex: "room",
        },
        {
            title: "Spill Type",
            key: "1",
            dataIndex: "spill_type",
        },
        {
            title: "Name",
            key: "1",
            dataIndex: "generated_by_name",
        },
        {
            title: "Action Taken by Cleaning Staff",
            key: "1",
            dataIndex: "",
            render: (_, record) => {
                if (record.comment_count === 0) {
                    return <></>;
                } else {
                    return (
                        <>
                            Open Comments
                            <Button onClick={() => openMessageChat(record,() => setIsCommentsModalOpen(true))}>
                                <MessageOutlined />
                            </Button>
                        </>
                    );
                }
            },
        },
        {
            title: "Attended  by",
            key: "1",
            dataIndex: "attended_by",
        },
        {
            title: "Date Completed",
            key: "1",
            dataIndex: "attended_date",
        },
        {
            title: "Status",
            key: "1",
            dataIndex: "status",
            render: (_, record) => {
                if (record.status === "Pending")
                    return (
                        <>
                            <Tag
                                color="magenta"
                                className="justify-content-center txt-color"
                            >
                                {record.status}
                            </Tag>
                        </>
                    );
                else if (record.status === "Completed")
                    return (
                        <>
                            <Tag
                                color="green"
                                className="justify-content-center"
                            >
                                {record.status}
                            </Tag>
                        </>
                    );
                else if (record.status === "Rejected")
                    return (
                        <>
                            <Tag
                                color="red"
                                className="justify-content-center"
                            >
                                {record.status}
                            </Tag>
                        </>
                    );
                else {
                    return <></>;
                }
            },
        },
    ];

   
    return (
        <Fragment>
            <StickySubHeader>
                <div className="communication_header">
                    <div className="container">
                        <Row align="middle" className="justify-content-sm-start justify-content-center">
                                <div>
                                    <h2 className="m-0">
                                        Welcome, {facility_name}
                                    </h2>
                                </div>
                        </Row>
                    </div>
                </div>
                <div className="container">
                    <Row
                        align="middle"
                        className="w-100 justify-content-sm-end justify-content-center date_row"
                    >
                        {/* <div> */}
                        <Col span={24}>
                            <div className="py-2">
                                <Row gutter="10" justify="end">
                                    <Col
                                        span={24}
                                        xs={24}
                                        sm={8}
                                        lg={6}
                                        xl={3}
                                        className="mt-2 mt-md-0"
                                    >
                                        <DatePicker
                                            defaultValue={
                                                rangePickerValue.startDate
                                            }
                                            onChange={(date) =>
                                                handleDateChange(date, 0)
                                            }
                                            allowClear={false}
                                            format={DATE_FORMAT_DD_MM_YYYY}
                                            style={{ width: "100%" }}
                                        />
                                    </Col>
                                    <Col
                                        span={24}
                                        xs={24}
                                        sm={8}
                                        lg={6}
                                        xl={3}
                                        className="mt-2 mt-md-0"
                                    >
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            value={rangePickerValue.endDate}
                                            onChange={(date) =>
                                                handleDateChange(date, 1)
                                            }
                                            allowClear={false}
                                            format={DATE_FORMAT_DD_MM_YYYY}
                                            style={{ width: "100%" }}
                                        />
                                    </Col>
                                    <Col
                                        span={24}
                                        xs={24}
                                        sm={8}
                                        lg={6}
                                        xl={3}
                                        className="mt-2 mt-md-0"
                                    >
                                        <Select
                                            defaultValue={'All'}
                                            style={{ width: "100%" }}
                                            onChange={handleStatusChange}
                                            options={[
                                                {
                                                    value: 'All',
                                                    label: "All",
                                                },
                                                {
                                                    value: 'Pending',
                                                    label: "Pending",
                                                },
                                                {
                                                    value: "Completed",
                                                    label: "Completed",
                                                },
                                                {
                                                    value: "Rejected",
                                                    label: "Rejected",
                                                },
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        {/* </div> */}
                    </Row>
                </div>
            </StickySubHeader>
            <div className="container">
                <Row>
                    <Col span={24}>
                        <div className="mt-4">
                            <Card className="date_wrapper">
                                <Table
                                    loading={loading}
                                    dataSource={communicationList}
                                    columns={tableColumns}
                                    scroll={{ x: 700 }}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
            {isCommentsModalOpen && (
                <CommentsModal
                    open={isCommentsModalOpen}
                    onCancel={() => setIsCommentsModalOpen(false)}
                    id={communicationId}
                />
            )}
        </Fragment>
    );
};

export default Communication;
