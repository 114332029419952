import { Button, Col, Form, Input, Row } from "antd";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserLogin } from "../../redux/actions/login/login.action";
import { SendNotification, setLocalStorageItems } from "../../utils/utilities";

const loginFormRules = {
  email: [
    {
      required: true,
      message: "Please enter username",
    }
  ],

  password: [
    {
      required: true,
      message: "Please enter password",
    },
  ],
};

const LoginForm = ({fid}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [btnLoading, setBtnLoading] = useState(false)

  const handleUserLogin = (values) => {
    setBtnLoading(true)
    dispatch(UserLogin(values)).then((res) => {
      
      const { payload : { success, data } } = res;

      if(success) {
        setLocalStorageItems({ setAll: true, items: data[0], skipKey: ['password'] });
        setLocalStorageItems({ set: true, item: { key: 'isAuthenticated', value: true }});
        navigate('/dashboard')
      } else{
        SendNotification({ type: 'error', message: "Username or password is wrong"})
      }
      setBtnLoading(false)
    })
  }

  return (
    <Fragment>
      <Form onFinish={handleUserLogin} layout="vertical" name="login-form">
        <Form.Item name="user_name" label="Username" rules={loginFormRules.email}>
          <Input />
        </Form.Item>
        <Form.Item name="password" label={"Password"} rules={loginFormRules.password}>
          <Input.Password />
        </Form.Item>
        <Row gutter={8}>
          <Col span={24} sm={fid ? 12 : 24}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block 
                loading={btnLoading}
              >
                {fid ? 'Sign is as Staff' : 'Sign In'}
              </Button>
            </Form.Item>
          </Col>
          {fid && (
            <Col span={24} sm={12}>
              <Button
                type="primary"
                block 
                onClick={() => navigate(`/g/raise-communications`)}
              >
                Sign in as Guest
              </Button>
            </Col>
          )}
        </Row>

      </Form>
    </Fragment>
  );
};

export default LoginForm;
