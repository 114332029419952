import MainLayout from "components/layout";
import PageNoteFound from "components/layout/PageNoteFound";
import AddNewCommunication from "pages/addNewCommunication";
import Communication from "pages/communications";
import Dashboard from "pages/dashboard";
import Login from "pages/login";
import SignOut from "pages/signOut";

import React, { Fragment } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import UnRestrictedRoutes from "./UnRestrictedRoutes";

const AppRoutes = () => {
  return (
    <Fragment>
      <BrowserRouter>
            <Routes>
                {/* Public Routes */}
                <Route path="/login" element={<UnRestrictedRoutes>{" "}<Login />{" "}</UnRestrictedRoutes>}/>
                <Route path="/logout" element={ <SignOut /> } />
                <Route path="/not-found" element={<PageNoteFound />} />
                <Route path="g" element={<MainLayout />} >
                  <Route path='raise-communications' element={ <UnRestrictedRoutes><AddNewCommunication /></UnRestrictedRoutes>} />
                </Route>

                
                {/* Requires Authentication */}
                <Route path="/" element={<ProtectedRoutes><MainLayout /></ProtectedRoutes>}>
                    <Route path="/dashboard" element={ <ProtectedRoutes> <Dashboard /> </ProtectedRoutes> } />
                    <Route path="/all-communications" element={ <ProtectedRoutes> <Communication /> </ProtectedRoutes> } />
                    <Route path="/raise-communications" element={ <ProtectedRoutes> <AddNewCommunication /> </ProtectedRoutes> } />
                </Route>

                <Route path="*" element={ <Navigate to="/not-found" /> } />


            </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

export default AppRoutes;
